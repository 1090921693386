import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-center sm:mr-1 h-9 pt-2 mb-1 rounded-md transition-all duration-50", _ctx.isPlaceHolderDay ? 'bg-gray-50' : _ctx.dynamicClasses]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onDaySelect && _ctx.onDaySelect(...args)))
  }, [
    _withDirectives(_createElementVNode("div", null, _toDisplayString(_ctx.day), 513), [
      [_vShow, !_ctx.isPlaceHolderDay]
    ])
  ], 2))
}