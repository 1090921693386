
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  unref as v,
  watch,
} from "vue";
import MDatePickerDays from "@/components/utils/input/mdatepicker/MDatePickerDays.vue";
import MSimpleDropdown from "@/components/utils/input/dropdowns/MSimpleDropdown.vue";
import MSimpleDropdownButton from "@/components/utils/input/dropdowns/MSimpleDropdownButton.vue";

export default defineComponent({
  name: "MDatePicker",
  components: {MSimpleDropdownButton, MSimpleDropdown, MDatePickerDays},
  emits: {
    onDate: (date: unknown) =>
        Object.prototype.toString.call(date) === "[object Date]" || true,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    resetSelectedToggle: {
      type: Boolean,
      required: false,
    },
    initialDate: {
      type: Date,
      required: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const months = [
      "Jaanuar",
      "Veebruar",
      "Märts",
      "Aprill",
      "Mai",
      "Juuni",
      "Juuli",
      "August",
      "September",
      "Oktoober",
      "November",
      "Detsember",
    ];
    const today = new Date();
    const selectedDate = ref<Date | undefined>(undefined);
    const activeYear = ref<number>(today.getFullYear());
    const activeMonth = ref<number>(today.getMonth());
    const activeDay = ref<number>(today.getDate());
    const activeMonthStr = computed(() => months[v(activeMonth)]);
    const showActiveDay = computed(() => {
      if (selectedDate.value)
        return (
            activeYear.value === selectedDate.value.getFullYear() &&
            activeMonth.value === selectedDate.value.getMonth()
        );
      else return false;
    });

    const changeYear = (direction: number) => activeYear.value += direction

    const changeMonth = (direction: number) => {
      if (direction === 1) {
        if (v(activeMonth) === 11) {
          activeMonth.value = 0;
          activeYear.value += 1;
        } else {
          activeMonth.value += 1;
        }
      } else if (direction === -1) {
        if (v(activeMonth) === 0) {
          activeMonth.value = 11;
          activeYear.value -= 1;
        } else {
          activeMonth.value -= 1;
        }
      }
    };
    const isDropdown = ref(false);
    const dropDownID = "datepicker-dd";
    const changeDay = (day: number) => {
      activeDay.value = day;
      selectedDate.value = new Date(
          activeYear.value,
          activeMonth.value,
          activeDay.value,
          23, 59, 59
      );
      isDropdown.value = false;
      emit("onDate", selectedDate.value);
    };

    const daysInMonth = computed(() => new Date(activeYear.value, activeMonth.value + 1, 0).getDate());
    const firstCalendarDayOfActiveMonth = computed(() => {
      const day = new Date(activeYear.value, activeMonth.value, 1).getDay()
      return day === 0 ? 7 : day
    })

    const buttonText = computed(() => {
      if (selectedDate.value) {
        const day = selectedDate.value.getDate();
        const month = selectedDate.value.getMonth();
        const year = selectedDate.value.getFullYear();
        return `${day} ${months[month]} ${year}`;
      } else {
        return ''
      }
    });

    const title = computed(() => props.placeholder)
    const setIsDropdown = (newValue: boolean) => {
      if (props.disabled) return
      (isDropdown.value = newValue);
    }
    onMounted(async () => {
      if (props.initialDate) {
        selectedDate.value = props.initialDate;
        activeYear.value = selectedDate.value.getFullYear();
        activeMonth.value = selectedDate.value.getMonth();
        activeDay.value = selectedDate.value.getDate();
        emit("onDate", selectedDate.value);
      }
      window.addEventListener("click", (e: MouseEvent) => {
        if (v(isDropdown)) {
          const target = e.target as HTMLDivElement;
          if (!target.closest(`#${dropDownID}`)) setIsDropdown(false);
        }
      });
    });

    watch(
        () => props.resetSelectedToggle,
        () => {
          selectedDate.value = undefined;
          activeYear.value = today.getFullYear();
          activeMonth.value = today.getMonth();
          activeDay.value = today.getDate();
          emit("onDate", selectedDate.value);
        }
    );
    return {
      daysInMonth,
      activeMonthStr,
      changeMonth,
      changeYear,
      activeYear,
      activeDay,
      changeDay,
      showActiveDay,
      isDropdown,
      dropDownID,
      title,
      setIsDropdown,
      buttonText,
      selectedDate,
      firstCalendarDayOfActiveMonth
    };
  },
});
