
import {computed, defineComponent, onMounted, ref} from "vue";
import MDatePickerDay from "@/components/utils/input/mdatepicker/MDatePickerDay.vue";

enum WeekDay {
  E = 'E',
  T = 'T',
  K = 'K',
  N = 'N',
  R = 'R',
  L = 'L',
  P = 'P'
}

export default defineComponent({
  name: "MDatePickerDays",
  components: {MDatePickerDay},
  emits: {
    onDaySelect: (day: unknown) => typeof day === "number",
  },
  props: {
    daysInMonth: {
      type: Number,
      required: true,
    },
    activeDay: {
      type: Number,
      required: false,
    },
    showActiveDay: {
      type: Boolean,
      required: true,
    },
    firstCalendarDayOfActiveMonth: {
      // 1. monday, 2. tuesday ...
      type: Number,
      required: true
    }
  },
  setup(props, {emit}) {
    const weekDays: WeekDay[] = Object.values(WeekDay)
    const hoverWeekDay = ref<WeekDay | undefined>(undefined)
    const onDaySelect = (day: number) => emit("onDaySelect", day);
    const addedEmptyDays = computed(() => props.firstCalendarDayOfActiveMonth - 1)
    const extraDays = computed(() => Math.abs((props.daysInMonth + addedEmptyDays.value) % 7 - 7))
    const mouseOnDay = (day?: number | undefined) => {
      hoverWeekDay.value = day
          ? weekDays[(6 + day + addedEmptyDays.value) % 7]
          : undefined
    }
    return {onDaySelect, addedEmptyDays, weekDays, extraDays, mouseOnDay, hoverWeekDay};
  },
});
