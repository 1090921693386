import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "max-w-sm flex flex-wrap mx-auto" }
const _hoisted_2 = { class: "grid grid-cols-7 w-full text-xs" }
const _hoisted_3 = { class: "grid-cols-7 grid w-full text-sm md:text-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDatePickerDay = _resolveComponent("MDatePickerDay")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weekDays, (weekDay) => {
          return (_openBlock(), _createElementBlock("div", {
            key: weekDay,
            class: _normalizeClass(["mb-1 text-center transition-all duration-50", _ctx.hoverWeekDay === weekDay ? 'text-gray-900 font-semibold' : 'text-gray-500'])
          }, _toDisplayString(weekDay), 3))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addedEmptyDays, (day) => {
          return (_openBlock(), _createBlock(_component_MDatePickerDay, {
            key: `date-picekr-empty-day_${day}`,
            "show-active-day": false,
            "days-in-month": _ctx.addedEmptyDays,
            day: day,
            "is-place-holder-day": true
          }, null, 8, ["days-in-month", "day"]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.daysInMonth, (day) => {
          return (_openBlock(), _createBlock(_component_MDatePickerDay, {
            key: `day_picker_date_${day}`,
            day: day,
            "days-in-month": _ctx.daysInMonth,
            "active-day": _ctx.activeDay,
            "show-active-day": _ctx.showActiveDay,
            "is-place-holder-day": false,
            onOnDaySelect: _ctx.onDaySelect,
            onMouseenter: ($event: any) => (_ctx.mouseOnDay(day)),
            onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mouseOnDay(undefined)))
          }, null, 8, ["day", "days-in-month", "active-day", "show-active-day", "onOnDaySelect", "onMouseenter"]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extraDays, (day) => {
          return (_openBlock(), _createBlock(_component_MDatePickerDay, {
            key: `date-picekr-extra-day_${day}`,
            "show-active-day": false,
            "days-in-month": _ctx.extraDays,
            day: day,
            "is-place-holder-day": true
          }, null, 8, ["days-in-month", "day"]))
        }), 128))
      ])
    ])
  ]))
}